.page404 {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
  }

  .error {
    text-align: center;
    background-color: #fff;
    padding: 80px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .error h1 {
    margin: 0;
    font-size: 130px;
    margin-bottom: 50px;
    color: var(--primary500);
  }

  .error p {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #555;
  }

  .error a {
    text-decoration: none;
    color: #fff;
    background-color: var(--primary500);
    padding: 15px 40px;
    border-radius: 30px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .error a:hover {
    background-color: var(--primary500);
  }

  @media (max-width: 500px) {
    .error {
      padding: 20px;
    }

    .error a {
      padding: 12px 30px;
    }
    .error h1 {
      font-size: 60px;
      margin-bottom: 20px;
    }
    .error p {
      font-size: 24px;
    }
  }
}
