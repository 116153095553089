.logolayout {
  background-color: var(--blue600);
  .logotitle {
    color: var(--light);
    margin-top: 7px;
    margin-left: 17px;
  }
  .bottomlink {
    margin-top: 20px;
    display: flex;
    align-self: center;
    .currentR {
      text-align: center;
    }
  }
  .loginlink {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .btn-tertiary {
    margin-left: 10px;
    font-size: 18px;
    text-align: center;
    line-height: 21.6px;
  }

  .topelement {
    position: absolute;
    float: right;
    height: 100%;
    top: 0px;
    left: 0px;
  }
  .bottomelement {
    position: absolute;
    bottom: 0px;
    left: 28%;
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
  .im2 {
    width: 34.5%;
    min-width: 40px;
  }
  .im1 {
    width: 34.5%;
    min-width: 40px;
  }
  .section {
    height: 100vh;
  }
  .title {
    margin-top: 38px;
  }
  .backcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .backimg {
    margin-top: -8px;
  }
  .backtext {
    margin-left: 15px;
    font-size: 22px;
  }
  .formsection {
    height: 100%;
    width: 50vw;
    z-index: 3;
    background-color: var(--light);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 0px;
  }
  .successContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    .successimg {
      width: 90px;
      height: 90px;
    }
    .successTitle {
      margin-top: 50px;
    }
    .successLink {
      width: 100%;
      margin-top: 40px;
    }
  }
  .formsection > div {
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 120px;
    padding-right: 190px;
  }

  @media (max-width: 1200px) {
    .formsection > div {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: 768px) {
    .formsection {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .formsection > div {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    margin-top: 24px;
    justify-content: flex-end;
  }
  .labelimg{
    margin-right: 10px;
    margin-top: -4px;
  }
  .subscription{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding:16px;
    margin-bottom:14px;
    border: 2px solid var(--blue400) ;
    border-radius: 14px;
    background-color:var(--light);
    img{
      margin-right: 10px;
    }
    .legend ,.currentR{
      margin-top: 5px;
    }
   
  }
  .subselected{
    border-color: var(--primary500);
    background-color:var(--primary300);
  }
  .subtitle + .title{
    margin-top: 20px;
  }

}
