.appmodal{
    border-radius: 30px;
    min-width: 519px;
    max-width: 519px;
    padding: 32px;
}
.confirmfacture{
    min-width: 411px;
    max-width: 411px;
}
.appmodal .modalcontent{
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 35px;
}
.appmodal .modaltext{

      flex-grow: 1; display: flex; align-items: center; flex-direction: column;
}
.appmodal .modaldesc{
    margin-top: 10px;
    text-align: center;
}
.appmodal .modaltitle{
 
    text-align: center;
}

@media (max-width: 636px) {
.appmodal{
    min-width: 26vw;
}

}