.apptable {
  --bgtable: white;
  --bgrowpair: var(--light);
  --bgrowimpair: #f4f4f7;
  --bgrowfocus: var(--light);
  --bgheader: #00354F;
  --colorheader: #fff;
  --headericoncolor: var(--primary500);
  --paginationcolor: var(--primary500);
  --circularprogress: var(--primary500);
  --bgindicator: #fcf1ef;
  --colorindicator: var(--primary500);
  --scrollcolor: #00354F;
  --linearprogressactive: var(--primary500);
  --linearprogressinactive: var(--primary500);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.MuiPopover-root {
  z-index: 6 !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.modalContent {
  width: 400px;
  overflow: hidden;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
@media (max-width: 468px) {
  .modalContent {
    width: 70vw;
  }
}
.apptable {
  height: 100%;
  overflow: hidden !important;
  display: flex;
  flex-grow: 1;
  padding-top: 16px;
  background-color: var(--bgtable);
  flex-direction: column;
}
.apptable>div{
  box-shadow: unset !important;
  display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.graycard .apptable,
.graycard .MuiTableContainer-root{
  background-color:#F4F4F7 !important;
  padding-top: 0px;
}
.graycard .css-10gei56{
  display: none;
}
.css-1mv0mgq-MuiTableContainer-root{
  flex-grow: 1;
}
table{
  overflow: hidden !important;
}

.cancel {
  margin-top: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.apptable ::-webkit-scrollbar {
  background-color: var(--bgtable) !important;
  width: 7px;
  height: 7px;
}
.apptable ::-webkit-scrollbar-thumb {
  background-color: var(--scrollcolor) !important;
  border-radius: 1.25em;
}

.apptable .css-1atu56z-MuiPaper-root,
.tabletop + div,
.tabletop + div > .MuiTableContainer-root {
  overflow-y: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable .input-container {
  margin-bottom: 0px !important;
}
.apptable .form-control,
.apptable .addbtn,
.apptable .filterbtn {
  height: 48px !important;
}

.apptable .css-uhr8s1-MuiTableContainer-root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable th {
  background-color: var(--bgheader);
  color: var(--colorheader);
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  height: 56px !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable tr th:first-child {
  border-radius: 10px 0 0 0px !important;
}
.apptable tr th:last-child {
  border-radius: 0 10px 0px 0 !important;
}
.Mui-TableHeadCell-Content-Wrapper{
  font-family: Rubik-Medium;
  font-size: 16px !important;
}
tfoot{
  display: none !important;
}
.MuiTableCell-body {
  height: 50px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
td {
  font-family: Rubik !important;
  font-size: 16px !important;
}

.MuiTableCell-body {
  height: 50px !important;
  border-width: 0px !important;
}

.apptable tbody > .rowpair {
  background-color: var(--bgrowpair) !important;
  border-width: 0px !important;
  height: 50px !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable tbody > .rowimpair {
  background-color: var(--bgrowimpair) !important;
  border-width: 0px !important;
  height: 50px !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable tbody > .MuiTableRow-root:hover {
  background-color: var(--bgrowfocus) !important;
}

.apptable .css-1atu56z-MuiPaper-root {
  z-index: 5 !important;
}
.apptable .css-1atu56z-MuiPaper-root:has(.css-nji4p8) {
  z-index: 20 !important;
}
.apptable .MuiCircularProgress-svg {
  color: var(--circularprogress) !important;
}
.apptable .MuiLinearProgress-root {
  background-color: var(--linearprogressactive);
}
.apptable .MuiLinearProgress-bar {
  background-color: var(--linearprogressinactive);
}
.apptable .MuiSvgIcon-root,
.MuiPaginationItem-root {
  color: var(--paginationcolor) !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable .Mui-TableHeadCell-Content .MuiSvgIcon-root {
  color: white !important;
}
.apptable .addbtn {
  height: 40px;
}
.apptable  .searchinput{
  width: 100%;
  max-width: 411px;
}

.apptable .MuiTableContainer-root[aria-busy="true"] {
  overflow: hidden;
}
.apptable .addsm {
  display: none;
}
.apptable .MuiButtonBase-root[aria-label="Changer la densité"] {
  display: none;
}
.apptable .MuiButtonBase-root[aria-label="Afficher/Masquer les colonnes"] {
  display: none;
}
.apptable .MuiButtonBase-root[aria-label="Passer en plein écran"] {
  display: none;
}

@media (max-width: 468px) {
  .apptable  .searchinput{
    min-width:200px;
  }
  .apptable .MuiPaginationItem-page,
  .MuiPaginationItem-ellipsis {
    display: none;
  }

  .apptable .disabledbtn {
    opacity: 0.2 !important;
  }

  
  .apptable .MuiInputBase-formControl {
    max-width: 50dvw;
  }
  .apptable .MuiFormLabel-root[for="mrt-rows-per-page"] {
    display: none;
  }
 
  
  .apptable .subtitle {
    font-size: 18px;
  }
  .apptable .indicator {
    font-size: 18px;
  }
  .apptable .tabletop {
    margin-bottom: 0px;
  }
}
.apptable .action:hover {
  opacity: 0.4;
}

.apptable .css-v9zqoq-MuiCollapse-root {
  display: none;
}
.apptable .indicator {
  background-color: var(--bgindicator);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 7px;
  color: var(--colorindicator);
  margin-inline-start: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.apptable .tabletop {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 16px;
}
.MuiBox-root.css-qpxlqp{
  width: 100%;
}
.MuiTablePagination-root.MuiBox-root.css-8k4lth{
  width: 100%;
}
span.length-filter {
  border: 1px solid gray;
  border-radius: 100%;
  padding: 3px 8PX 3px 8px;
  margin: 5px;
  font-size: 12px;
  background-color: #80808021;
}
/*
.MuiTableRow-head{
}
.MuiPaper-root{
  height: 100%;
  overflow: auto;
}
tbody>.MuiTableRow-root {

}

tbody>.Mui-selected {
}
 .MuiTableContainer-root {
}


.MuiTablePagination-root {
   
} */
/* table card style */
