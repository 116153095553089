.textinput{
   
}
.textinput .dropdown-menu {
  width: 100%;
  min-width: 0px;
  overflow: auto;
  max-height: 300px;
  min-height: 80px;
  inset: unset !important;
  transform: unset !important;
  padding-top: 8px;
  border-radius: 21px;
  padding-left: 24px;
  padding-right: 24px;
}
.textinput .dropdown-item {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px rgba(230, 231, 232, 1) solid;
  font-size: 14px;
  line-height: 16.8px;
  color: var(--black600);
}
.textinput .dropdown-item:focus,
.textinput .dropdown-item:hover {
  background-color: var(--light);
  color: var(--primary400);
}
.textinput .dropdown-item.active {
  background-color: var(--light);
  color: var(--primary500);
}