@font-face {
  font-family: "Rubik-Medium";
  src: url("./../fonts/Rubik-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik-SemiBold";
  src: url("./../fonts/Rubik-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik-Bold";
  src: url("./../fonts/Rubik-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik-ExtraBold";
  src: url("./../fonts/Rubik-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  src: url("./../fonts/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  src: url("./../fonts/Rubik-Regular.ttf") format("truetype");
}
:root {
  --primary300: #fcf1ef;
  --primary400: #eeaca0;
  --primary500: #e37461;
  --blue300: #f4f4f7;
  --blue400: #b3c2ca;
  --blue500: #668695;
  --blue600: #00354f;
  --black200: #e6e7e8;
  --black300: #81898d;
  --black400: #6c7477;
  --black500: #364349;
  --black600: #04141c;
  --gold400: #fffbf7;
  --gold500: #cfae67;
  --orange400: #f5e7d6;
  --orange500: #f6bd7a;
  --green400: #e4f6f2;
  --green500: #37c2a9;
  --pink400: #fbe9ff;
  --pink500: #e796fb;
  --blue1400: #e4e7fb;
  --blue1500: #6179f6;
  --light: #ffffff;
  --light100: #FAFAFA;
}

.root {
  padding: 0px;
}
::-webkit-scrollbar {
  background-color: var(--light);
  width: 7px /* 20px / 16px */;
}

::-webkit-scrollbar-thumb {
  background-color: #00354f;
  border: 0.25em /* 4px / 16px */ solid whites;
  border-radius: 1.25em /* 20px / 16px */;
}
 .graycard{
  background-color: #F4F4F7;
  border-radius: 20px;
  border-width: 0px;
  padding: 24px;
}
body {
  /* position: fixed; */
  height: 100%;
  width: 100%;
  margin: 0;
  scrollbar-color: #00354f #00354f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Rubik-Bold;
  color: var(--black600);
  background-color: var(--light);
  overflow-x: hidden;
  /* overflow: hidden; */
}

h1 {
  font-size: 40px;
  line-height: 47.4px;
  font-family: Rubik-Bold;
}
h2 {
  font-size: 34px;
  line-height: 47.4px;
  font-family: Rubik-Medium;
}
h3 {
  font-size: 28px;
  line-height: 33.18px;
  font-family: Rubik-Medium;
}
.title {
  font-size: 30px;
  line-height: 33.18px;
  font-family: Rubik-Medium;
}
.subtitle {
  font-size: 24px;
  line-height: 28.8px;
  font-family: Rubik-Medium;
}
.legend {
  font-size: 14px;
  line-height: 16.8px;
  font-family: Rubik;
}
.currentR {
  font-size: 18px;
  line-height: 21.6px;
  font-family: Rubik;
}

.currentS {
  font-size: 16px;
  line-height: 21.6px;
  font-family: Rubik;
}
.currentXS {
  font-size: 14px;
  line-height: 21.6px;
  font-family: Rubik;
}
.currentM {
  font-size: 18px;
  line-height: 21.6px;
  font-family: Rubik-Medium;
}

.tcenter{
  text-align: center;
}
.tleft{
  text-align: left;
}
.tright{
  text-align: right;
}
.tline{
  text-decoration: line-through;
}
.tag{
  border-radius: 20px;
  border-width: 1.2px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  width: unset !important;
  max-width: 90px;
  max-height: 26px !important;
   text-align: right;
  font-size: 14px;
  font-family: Rubik;
  line-height: 24px;
}
.tagwarning{
   background-color: #FFFBF7;
   color: #E68A00;
   border-color: #E68A00;
}
.tagsuccess{
  background-color: #FBFFF4;
  color: #619D00;
  border-color: #619D00;
}
.tagerror{
  background-color: #FFFAFA;
  color: #BC1700;
  border-color: #BC1700;
}

.button {
  border-radius: 30px;
  padding: 16px 20px 16px 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  box-shadow: unset !important;
  font-family: Rubik-SemiBold;
  font-size: 18px;
  line-height: 21.33px;
}
.backcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.backimg {
  margin-top: -8px;
}
.backtext {
  margin-left: 15px;
  font-size: 22px;
}

.btn-primary {
  background-color: var(--primary500);
  border-width: 0px;
  width: 100%;
  color:var(--light)
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: var(--primary400);
}

.btn-primary:disabled {
  background-color: var(--black300);
  color: var(--black200);
}
.graydisabled{
  width: 142px !important;
}


.btn-secondary {
  background-color: var(--primary300);
  width: 100%;
  border: var(--primary500) 2px solid ;

  margin: 0 auto;
  color: var(--primary500);
}
.dropdown-input-btn{
   border-width: 0px !important;
}

.btn-secondary:focus,
.btn-secondary:hover {
  background-color: var(--primary300) !important;
  border-color: var(--primary400) !important;
  color: var(--primary400);
}

.btn-secondary:disabled {
  background-color: var(--black200);
  border-color: var(--black300);
  color: var(--black300);
}

.btn-tertiary {
  color: var(--primary500);
  padding: 0px;
  margin: 0px;
  border-width: 0px;
  background-color: transparent;
  text-decoration: underline;
  text-decoration-color: var(--primary500);
  font-family: Rubik-Medium;
}
.btn-tertiary:focus,
.btn-tertiary:hover {
  color: var(--primary400) !important;
  text-decoration: underline;
  text-decoration-color: var(--primary400);
}
.btn-tertiary:disabled {
  color: var(--black300);
  text-decoration: underline;
  text-decoration-color: var(--black300);
}
a,
a:hover,
a:focus {
  text-decoration: none;
  color: var(--blue600);
}

.input-container {
  margin-bottom: 12px !important;
}

.input-group {
  z-index: 0 !important;
}

.input-group-icon {
  background-color: var(--light);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-color: var(--blue400);
  border-left-width: 0px;
 
}
.input-group-icon .iconBtn {
  border-width: 0px;
  padding: 0px;
  background-color: var(--light);
}
.input-group-icon .iconBtn:hover {
  opacity: 0.5;
}

.searchaddclient .form-check{
  margin-bottom: 0px !important;
}
.page{
  overflow: auto;
  height: 100dvh;
  margin: 0px;
  padding: 24px ;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.form-text {
  color: #81898d;
  font-family: Rubik;
  font-size: 14px;
  margin-top: 4px;
}
.form-label {
  font-family: Rubik;
  font-size: 14px;
  line-height: 16.8px;
  color: var(--black600);
}

.form-control,
.form-select {
  border-radius: 30px;
  border-width: 1px;
  background-color: var(--light);
  border-color: var(--blue400);
  height: 44px;
  font-size: 14px;
  font-family: Rubik;
  font-style: italic;
  line-height: 16.8px;
  color: var(--black600);
 
}


.form-control:hover,
.form-select:hover,
.form-control:hover ~ .input-group-icon {
  border-color: var(--primary400);
}
.form-control:focus,
.form-select:focus,
.form-control:focus ~ .input-group-icon {
  border-color: var(--primary500);
  box-shadow: unset !important;
  outline: 0 none !important;
}
.form-control-invalid,
.form-select-invalid,
.form-control-invalid ~ .input-group-icon {
  border-color: red;
}
.form-control-valid,
.form-select-valid,
.form-control-valid ~ .input-group-icon {
}
.form-control::placeholder {
  color: var(--black300);
}
.form-control:-webkit-autofill {
  -webkit-background-clip: text;
}
.form-control-icon {
  border-right-width: 0px !important;
}

.error-toast {
  color: #bc1700;
  font-family: Rubik;
  font-size: 14px;
}
.form-error-msg {
  color: #bc1700;
  font-size: 14px;
  font-family: Rubik;
  margin-top: 4px;
}
.dropdown-input {
  margin: 0px;
  padding: 0px;
  background-image: unset !important;
}
.tag{
  border-radius: 20px;
  border-width: 1.2px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  width: unset !important;
  max-width: 90px;
  max-height: 26px !important;
   text-align: right;
  font-size: 14px;
  font-family: Rubik;
  line-height: 24px;
}
.tagwarning{
   background-color: #FFFBF7;
   color: #E68A00;
   border-color: #E68A00;
}
.tagsuccess{
  background-color: #FBFFF4;
  color: #619D00;
  border-color: #619D00;
}
.tagerror{
  background-color: #FFFAFA;
  color: #BC1700;
  border-color: #BC1700;
}
.btn-link{
  height: 42px;
  padding: 10px 24px 10px 24px;
  font-size: 18px;
  max-width: 142px;
  text-decoration: unset !important;
  color: white !important;
  font-family: Rubik-Medium;

}
.btn-link:focus,.btn-link:hover{
  background-color: var(--primary500);
}
.btn-link-inactive{
  border: 1px solid rgba(238, 172, 160, 1);
  background-color:  rgba(255, 255, 255, 0.2);
  font-family: Rubik;
}
.btn-link-inactive:focus,
.btn-link-inactive:hover
{
  border: 1px solid rgba(238, 172, 160, 1);
  background-color:  rgba(255, 255, 255, 0) !important;
  font-family: Rubik;
}
.dropdown-input-disabled {
  background-color: #e9ecef;
  opacity: 0.8 !important;
}
.dropdown-input-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  margin: 0px;
  overflow: hidden;
  box-shadow: unset !important;
  outline: 0 none !important;
}
.dropdown-input-title {
  width: 95%;
  text-align: start;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: Rubik;
  line-height: 16.8px;
  color: var(--black600);
  font-style: italic;
  height: 100%;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
}
.dropdown-input-placeholder {
  color: var(--black300);
}
.dropdown-input-icons {
  display: flex;
  width: 62px;
  justify-content: flex-end;
  overflow: hidden;
  margin-inline-start: 4px;
}
.close-icon {
  position: absolute;
  pointer-events: fill;
}
.dropdown-menu {
  width: 100%;
  min-width: 0px;
  overflow: auto;
  max-height: 300px;
  min-height: 80px;
  inset: unset !important;
  transform: unset !important;
  border-radius: 21px;
  padding-left: 24px;
  padding-right: 24px;
}
.btnsmall{
  height: 48px !important;
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px rgba(230, 231, 232, 1) solid;
  font-size: 14px;
  font-family: Rubik;
  line-height: 16.8px;
  color: var(--black600);
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--light);
  color: var(--primary400);
}
.dropdown-item.active {
  background-color: var(--light);
  color: var(--primary500);
}
.btnclose{
  float: right;
  border-width: 0px;
  background-color: var(--light);
}
.inp100{
 max-width: 100% !important;
 width: 100%;
}
.assignclient .form-control{
  max-width: 100% !important;
}
.docpicker{
    background-color: white ;
    width: 387px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border-style: dashed;
    border-width: 1px;
    border-color: rgba(179, 194, 202, 1);
    cursor: pointer;
    padding: 5px;
}
.bigtagfacture{
  padding: 16px;
  border-radius: 16px;
  border-style: solid;
  border-width: 1;
  color: #04141c !important;
}

.mrleft{
  margin-left: 30px;
}
.btntarif{
  padding: 20px !important;
  height: 53px;
}

@media (max-width: 768px) {
  .docpicker{
    width: 100% !important;
  }
}