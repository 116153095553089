.sidebar {

  font-family: Rubik;
  color: white;
  background-color: var(--blue600);
  overflow: "hidden";
  ::-webkit-scrollbar {
    background-color: var(--blue600) !important;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--blue600) !important;
    border: 0.25em solid var(--light);
    border-radius: 1.25em;
  }
  div {
    background-color: var(--blue600);
  }
  .container {
    height: 100vh;
    padding: 0px;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
  .menu {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
  .menu ul {
    width: 100%;
  }
  .submenu-single > .ps-menu-button >.ps-submenu-expand-icon {
    display: none;
  }
  .submenu > .ps-menu-button:hover {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .submenu-active > .ps-menu-button {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .menuitem {
    font-size: 14px;
  
  }
 
  .menuitem > .ps-menu-button:hover {
    width: 100%;
    background-color: var(--blue600);
    color: var(--primary400);
  }
  .menuitem-active > .ps-menu-button {
    color: var(--primary500) !important;
    font-family: Rubik-Medium;
  }

  .icon {
    width: 16px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .logo > img {
    width: 100px;
  }

  .logout {
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--light);
    border-width: 0px;
    border: 2px solid var(--light);
  }

  .logout:focus,
  .logout:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.togglebar,
.btn-close {
  display: none;
}
@media (max-width: 768px) {
  .btn-close {
    display: block;
    color: white;
    z-index: 4;
    float: right;
    margin-right: 10px;
    margin-left: 10px;
  }
  .togglebar {
    display: flex;
    background-color: var(--blue600);
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sidecontent {
    display: none;
  }
}
